// Importing necessary modules and components from Angular and third-party libraries
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { decode } from 'punycode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { CalendarService } from '../calendar.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-e-public-services',
  templateUrl: './e-public-services.component.html',
  styleUrls: ['./e-public-services.component.scss'],
})
export class EPublicServicesComponent implements OnInit {
  // Declare class properties
  lang: string;
  showSpiner: boolean;
  isError = 0;
  serviceTypes: any = [];
  serviceIcons = [
    'nurse_icon.svg',
    'lab_icon.svg',
    'profile_icon.svg',
    'therapy_icon.svg',
    'nutrition_icon.svg',
    'assistant_icon.svg',
    'xray_icon.svg',
    'vitamin_icon.svg',
  ];
  serviceCategory: any = [];

  // ViewChild reference for the wizard component
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  latitude: any;
  longitude: any;
  isLoading = false;
  reservationStatus: any[] = [];
  form: FormGroup;
  mobilePattern = /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  pNamePattern =
    /^[a-zA-Z\u0621-\u064A ]{3,}\s[a-zA-Z\u0621-\u064A ]{3,}(\s[a-zA-Z\u0621-\u064A ]{3,})*$/;
  transNo = 0;
  sameDate = 1;
  nextDate: string;
  currentJustify: string;
  patName: any;
  patMobile: any;
  rDate: any;
  currentRating = 10;
  done = 0;
  now = new Date();
  currentDate = {
    year: this.now.getFullYear(),
    month: this.now.getMonth() + 1,
    day: this.now.getDate(),
  };
  showDateTimeSection = true;
  queryParams: any;
  selectedDateQueryParams: Date;
  user: any;
  formatted_address: any;
  addressLink: any;
  zoom = 17;
  sidebarClose: Boolean = false;
  appointmentBooked: Boolean = false;
  dir: string;
  activeTab = 'morning';
  mdate: any;
  homeDoctorVisit: boolean = false;
  minDate: Date;
  maxDate: Date;
  bsValue: Date = new Date();
  minMode: BsDatepickerViewMode = 'day';
  bsConfig: Partial<BsDatepickerConfig>;
  serviceDetails: any;
  availableAppointments: any = [];
  currentDateStr: any;
  tdate: NgbDateStruct;
  appointmentData: any = {};
  slotSerialNumber: any = '';
  searchText;
  model: any = {
    selectedServiceType: '',
    selectedDoctor: '',
    selectedDate: '',
    selectedTime: null,
    selectedServiceCategory: {},
  };
  serviceSearchQuery = '';
  serviceSearchName = 'service_mkt_en';
  selectedCatCode: any;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private apiHandler: ApiHandlerService,
    private cdRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private calendarService: CalendarService,
    private datePipe: DatePipe
  ) {
    // Subscribe to query parameters to check for homeDoctorVisit flag
    this.route.queryParams.subscribe((params) => {
      if (params.homeDoctorVisit) {
        this.homeDoctorVisit = true;
      }
    });

    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';

    this.form = new FormGroup(
      {
        fullName: new FormControl(null, [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('([A-Za-z\u0600-\u06FF]+\\s?)+'),
        ]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ]),
        city: new FormControl(null, [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]),
        clientMessage: new FormControl(null, [Validators.maxLength(300)]),
        insurance: new FormControl('0'),
      },
      { updateOn: 'blur' }
    );

    this.user = decode('currentUser');
  }
  ngOnInit() {
    this.serviceSearchName =
      this.lang == 'ar' ? 'service_mkt_ar' : 'service_mkt_en';
    this.currentDateStr = moment().format('YYYY-MM-DD').toString();
    const currentDate = new Date();
    this.bsConfig = Object.assign(
      {},
      {
        minDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        ),
        maxDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          31
        ),
        showWeekNumbers: false,
      }
    );
    this.getListQuery();
    if (this.lang == 'en') {
      $('aw-wizard').attr('dir', 'ltr');
      $('ng-select').attr('dir', 'ltr');
      $('.ng-option-label').addClass('text-left');
    } else {
      $('aw-wizard').removeAttr('dir');
      $('ng-select').removeAttr('dir');
      $('.ng-option-label').removeClass('dir');
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  get fullName() {
    return this.form.get('fullName');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get city() {
    return this.form.get('city');
  }

  get clientMessage() {
    return this.form.get('clientMessage');
  }
  get insurance() {
    return this.form.get('insurance');
  }

  // Function to fetch a list of data from a public service and process it.
  getListQuery() {
    let params = {
      sort: 'cat_code_oradb',
      sort_by: 'ASC',
    };
    this.apiHandler
      .callPublicListQuery('public-enaya-service', params)
      .subscribe(
        (data) => {
          if (data) {
            this.serviceTypes = data;
            if (this.serviceTypes.length > 0) {
              this.serviceTypes.forEach((el, ind) => {
                if (+el['cat_code_oradb'] > this.serviceIcons.length) {
                  el['icon'] = '../../../assets/img/svg/assistant_icon.svg';
                } else {
                  el['icon'] = `../../../assets/img/svg/${this.serviceIcons[+el['cat_code_oradb'] - 1]
                    }`;
                }
              });
            }
          }
        },
        (err) => {
          console.log('error====>', err);
        }
      );
  }

  // Function to fetch a list of service categories based on certain criteria.
  getServicewiseCatList(value) {
    let params = {
      sort: 'created_at',
      sort_by: 'DESC',
    };
    if (this.model.selectedServiceType) {
      this.spinnerService.show();
      params['cat_code_oradb'] = value ? value : this.selectedCatCode;
      this.apiHandler
        .callPublicListQuery('public-enaya-category-service', params)
        .subscribe(
          (data) => {
            if (data) {
              this.serviceCategory = data;
              this.spinnerService.hide();
            }
          },
          (err) => {
            console.log('error====>', err);
            this.spinnerService.hide();
          }
        );
    }
  }

  // Function to handle the selection of a service category.
  serviceCatSelected(event, item) {
    this.showDateTimeSection = item && item.scheduled_type == 0 ? false : true;
  }

  // Function to fetch available appointments based on specified criteria.
  getAvailableAppointments() {
    let params = {
      SpecificDate: this.currentDateStr,
    };
    if (
      this.model.selectedServiceCategory &&
      this.model.selectedServiceCategory.cat_code_oradb
    ) {
      params['category_number'] =
        +this.model.selectedServiceCategory.cat_code_oradb;
    }

    this.apiHandler
      .callAppointmentsGetQuery('specific-category', params)
      .subscribe(
        (data: any) => {
          if (
            data &&
            data.status == 'success' &&
            data.data &&
            data.data.length > 0
          ) {
            // this.availableAppointments = data.data;
            this.availableAppointments = data.data.filter((el) => {
              el.resTime = el.resTime.replace('Z', '');
              return (
                new Date(this.currentDateStr).getDate() ==
                new Date(el.resTime).getDate() && (new Date().getTime() < new Date(el.resTime).getTime())
              );
            });
          }
        },
        (err) => {
          console.log('error====>', err);
        }
      );
  }

  // Function to format a date string according to the specified type (English/Arabic).
  formatedTitle(date, type) {
    const inputDate = new Date(date);
    const options: any = {
      timeZone: "Asia/Riyadh", // KSA timezone
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    if (type === "EN") {
      return inputDate.toLocaleString('en-US', options);
      // return inputDate.toLocaleString('en-US', options).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2-$1-$3');
    }
    if (type === "AR") {
      return inputDate.toLocaleString('ar-SA', options);
      // return inputDate.toLocaleString('ar-SA', options).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2-$1-$3');
    }
  }

  // Function to update latitude and longitude based on provided location coordinates.
  location(x) {
    this.latitude = x.coords.lat;
    this.longitude = x.coords.lng;
  }

  // Function to handle changes in the selected address and update related properties.
  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.formatted_address = address.formatted_address;
    this.addressLink =
      `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.replace(
        /\s/g,
        '+'
      );
  }

  // Function to set the current location based on the user's device geolocation.
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.addressLink =
          `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.toLowerCase();
        this.formatted_address = '';
      });
    }
  }

  // Function to handle changes in the selected date.
  dateChange(value: Date): void {
    this.currentDateStr = moment(value).format('YYYY-MM-DD').toString();
    this.getAvailableAppointments();
    this.model.selectedDate = value;
  }

  // Function to handle booking a new appointment.
  newAppBook() {
    if (this.form.invalid) {
      return false;
    }

    let data1 = {
      client_name: this.fullName.value,
      client_mobile: this.phoneNumber.value,
      client_city: this.lang == 'ar' ? 'جدة' : 'Jeddah', // this.city.value
      service_cat_code_id: +this.model.selectedServiceCategory.cat_code_oradb,
      client_mrn: '',
      formatted_address: this.formatted_address ? this.formatted_address : '',
      url: this.addressLink ? this.addressLink : '',
      insurance: this.insurance.value,
      geometry: {
        location: {
          lat: this.latitude ? this.latitude : '',
          lng: this.longitude ? this.longitude : '',
        },
      },
      order_items: [
        {
          service_code: +this.model.selectedServiceCategory.service_code_oradb,
          client_message: '',
        },
      ],
    };
    if (this.showDateTimeSection) {
      data1['app_serial'] = this.slotSerialNumber;
      this.apiHandler
        .callAppointmentsBook('service-appointment', data1)
        .subscribe(
          (data: any) => {
            if (data && data.error) {
              console.log('error====>', data.error);
            }
            if (data && data.success) {
              this.appointmentData = data;
              this.appointmentBooked = true;
            }
          },
          (err) => {
            console.log('error====>', err);
          }
        );
    } else {
      data1['received_order_type_id'] = 2;
      data1.order_items[0]['client_message'] = this.clientMessage.value;
      data1.order_items[0]['suggested_date_time'] = '';
      this.apiHandler.publicPostQueryWithBody('enaya-order', data1).subscribe(
        (data: any) => {
          if (data && data.error) {
            console.log('error====>', data.error);
          }
          if (data && data.order) {
            this.appointmentData = data.order;
            this.appointmentBooked = true;
          }
        },
        (err) => {
          console.log('error====>', err);
        }
      );
    }
  }

  // Function to navigate to the e-services route.
  goToHome() {
    this.router.navigate(['/e-services']);
  }

  // Function to initiate a new appointment.
  newAppointment() {
    window.location.reload();
    this.resetFormAppointment();
    this.appointmentBooked = false;
  }

  // Function to reset appointment-related properties and form.
  resetFormAppointment() {
    this.model = {
      selectedServiceType: '',
      selectedDoctor: '',
      selectedDate: '',
      selectedTime: null,
      selectedServiceCategory: {},
    };
    this.slotSerialNumber = '';
    this.formatted_address = '';
    this.addressLink = '';
    this.latitude = '';
    this.longitude = '';
    this.form.reset();
    let currentDate = new Date();
    this.bsConfig = Object.assign(
      {},
      {
        minDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        ),
        maxDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          31
        ),
      }
    );
    this.bsValue = new Date();
  }

  // Custom validator function to check for whitespace-only or empty string values.
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // Function to handle a call event for a specific service.
  call(event, service) {
    if (service && service.cat_code_oradb == '3') {
      this.router.navigate(['e-public'], {
        queryParams: { homeDoctorVisit: true },
      });
    }
  }

  // Function to handle selecting a time slot.
  selectTimeSlot(event, item) {
    if (item && item.appSerial) {
      this.slotSerialNumber = item.appSerial;
      this.model.selectedTime = item.resTime;
    }
  }

  // Function to open a details popup with specific content and item information.
  openDetailsPopup(content, item) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    this.serviceDetails = item;
  }

  // Function to close the currently open popup.
  closePopup() {
    this.modalService.dismissAll();
  }

  // Function to cancel the service and navigate to the e-services route.
  cancelService() {
    this.router.navigate(['/e-services']);
  }

  // Function to set the category type and trigger fetching service-wise category list.
  onSetCategoryType(value) {
    this.selectedCatCode = value;
    this.getServicewiseCatList(value);
  }

  // Function to save appointment information to the calendar.
  saveAppointmentToCalendar(appointmentData) {
    const startDate = new Date(appointmentData.date_and_time);
    const endDate = new Date(appointmentData.date_and_time);
    const title =
      this.lang == 'ar'
        ? `موعد - عناية متجددة للرعاية الصحية`
        : `Appointment - Enaya Health Care`;
    const location = '';
    const description =
      this.lang == 'ar'
        ? `الخدمة: ${appointmentData.service_name_ar}`
        : `Service Name: ${appointmentData.service_name_en}`;
    this.calendarService.addEventToCalendar(
      title,
      startDate,
      endDate,
      description,
      location
    );
  }

  // Function to share appointment information via WhatsApp.
  shareWhatsapp(appointmentData) {
    const date = new Date(appointmentData.date_and_time);
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd, , h:mm a');
    const message =
      this.lang == 'en'
        ? `*Enaya For Health Care*\nYour appointment has been booked successfully\nOrder Number: ${appointmentData.order_number}\nPatient Name: ${appointmentData.client_name}\nService Name: ${appointmentData.service_name_en}\nService Type: ${appointmentData.category_en}\nAppointment Date & Time: ${formattedDate}`
        : `*عناية متجددة للرعاية الصحية*\nتم حجز موعدكم بنجاح\nرقم الطلب: ${appointmentData.order_number}\nاسم المريض: ${appointmentData.client_name}\nاسم الخدمة: ${appointmentData.service_name_ar}\nنوع الخدمة: ${appointmentData.category_ar}\nتاريخ الموعد: ${formattedDate}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }
}
